.swiper-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  height: 100%;
  img {
    width: 100%;
  }
}

.swiper-lazy-preloader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.gallery-top {
  width: 100%;
}

.gallery-thumbs {
  box-sizing: border-box;
  padding: 10px 0;
  cursor: pointer;
}

.gallery-thumbs .swiper-slide {
  width: 33.333%;
  height: auto;
  opacity: 0.6;
}

.gallery-thumbs .swiper-slide-thumb-active {
  opacity: 1;
}