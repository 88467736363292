@charset "UTF-8";

/*
	STYLE SHEET FOR JQUERY LISTNAV PLUGIN V 2.4.0, 3/11/2014
	For more information, visit http://esteinborn.github.com/jquery-listnav
*/

.listNav,
.ln-letters {
  overflow:hidden;
}

.listNavHide {
  display: none;
}

.listNavShow {
  display: list-item;
}

.letterCountShow {
  display: block;
}

.ln-letters {
  a {
    font-size:0.9em;
    display:block;
    float:left;
    padding:2px 6px;
    border:1px solid silver;
    border-right:none;
    text-decoration:none;
  }

  .ln-last {
    border-right: 1px solid silver;
  }

  a:hover,
  .ln-selected {
    background-color:#eaeaea;
  }

  .ln-disabled {
    color:#ccc;
  }

}

.ln-letter-count {
  position: absolute;
  top:0;
  left:0;
  width:20px;
  text-align:center;
  font-size:0.8em;
  line-height:1.35;
  color:#336699;
}