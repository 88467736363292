@import "partials/x-editable";
@import "partials/listnav";
@import "partials/swiper";

body, h1, h2, h3, h4, h5, h6,
table td,
.navbar
{
  font-weight: 400;
  color: #636b6f;
}

.navbar-custom {
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: center;
}

.tooltip-inner {
  border-radius: 0;
}

.navbar.navbar-light .breadcrumb .nav-item .nav-link, 
.navbar.navbar-light .navbar-nav .nav-item .nav-link {
  color: #636b6f;
}

table th {
  font-weight: 500;
}

.btn-tag {
  font-weight: 500;
  color: #636b6f;
}

.container-main {
  min-height: calc(100vh - 130px);
}

.container-fluid-custom {
  @media (min-width: 768px){
    margin: auto;
  }
  @media (min-width: 1200px){
    width: 95%;
  }
  @media (min-width: 1400px) {
    width: 80%;
  }
}

.custom-checkbox--products-table {
  padding-left: 1.2rem;
  .custom-control-label::before,
  .custom-control-label::after {
    left: -1.1rem;
  }
}

.text-vcode {
  width: 50px;
  display: inline-block;
  @media (min-width: 768px) {
    width: auto;
  }
}

.table-editable {
  cursor: pointer;
  position: relative;
  thead th {
    font-size: .8rem;
    vertical-align: middle;
  }
  @media (min-width: 768px) {
    cursor: auto;
    thead th {
      font-size: .9rem;
    }
  }
}

.table-fixed-column {
  position: fixed;
  width: auto;
  z-index: 998;
  background: #ffffff;
}

.table-editable--scroll {
  height: 100vh;
  overflow: auto;
}

.navbar-brand {
  flex: 1 1 auto;
  @media (min-width: 768px) {
    flex: 0 1 auto;
  }
}

.navbar-brand--forsage {
  padding: 0;
  height: 32px;
  img {
    height: 100%;
  }
  @media (min-width: 992px) {
    height: 42px;
  }
}

.navbar-brand--square {
  @media (min-width: 992px) {
    width: 42px;
    overflow: hidden;
  }
  @media (min-width: 1600px) {
    width: auto;
  }
}

.card--tags {
  .news-tags {
    .btn.btn-sm {
      padding: .1rem 0.6rem;
      @media (min-width: 768px) {
        padding: .5rem 1.6rem;
      }
    }
  }
}

.table-heading-fixed {
  top:0;
  position:fixed;
  width:auto;
  display:none;
  border:none;
  background: #ffffff;
  z-index: 999;
  margin: 0 !important;
  thead {
    th {
      border-bottom: 1px solid #dee2e6;
    }
  }
}

.img-popover-hover {
  max-width: 100%;
  height: auto;
  @media (min-width: 768px) {
    width: 600px;
  }
}

.progress-timer {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.container--page {
  min-height: calc(100vh - 130px);
}

.img-fluid--product-img {
  max-width: 90px;
  &:hover {
    +.product-img-controls {
      display: block;
    }
  }
  @media (min-width: 768px) {
    max-width: 100px;
  }
}

.product-img-controls {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  &:hover {
    display: block;
  }
}

.selectpicker+.dropdown-toggle {
  padding: .63rem .75rem;
}

.selectpicker--custom+.dropdown-toggle {
  margin: 0;
}

.hot {
  position: relative;
  &:after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: red;
    position: absolute;
    top: -5px;
    right: -5px;
  }
}

.hot--news {
  &:after {
    top: 8px;
    right: 3px;
  }
}

.collapse.dont-collapse-sm {
  @media (min-width: 768px) {
    display: block;
    height: auto !important;
    visibility: visible;
  }
}

.form-control--center {
  &::-webkit-input-placeholder { /* WebKit browsers */
    text-align: center;
  }
  &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    text-align: center;
  }
  &::-moz-placeholder { /* Mozilla Firefox 19+ */
    text-align: center;
  }
  :-ms-input-placeholder { /* Internet Explorer 10+ */
    text-align: center;
  }
  &::placeholder {
    text-align: center;
  }
}

.form-control--placeholder-xs {
  @media (min-width: 768px) {
    &::placeholder {
      color:    transparent;
    }
    &::-webkit-input-placeholder { /* WebKit browsers */
      color:    transparent;
    }
    &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
      color:    transparent;
    }
    &::-moz-placeholder { /* Mozilla Firefox 19+ */
      color:    transparent;
    }
    :-ms-input-placeholder { /* Internet Explorer 10+ */
      color:    transparent;
    }
  }
}

.btn-sm--c {
  @media (max-width: 768px) {
    padding-left: 1rem!important;
    padding-right: 1rem!important;
    padding-top: .25rem!important;
    padding-bottom: .25rem!important;
  }
}

.page-link--c {
  @media (max-width: 768px) {
    padding-left: .5rem!important;
    padding-right: .5rem!important;
    padding-top: .25rem!important;
    padding-bottom: .25rem!important;
  }
}

.pagination .page-item {
  display: inline-block;
  height: 34px;
}

[data-export].disabled {
  pointer-events: none;
}

.pe-none {
  pointer-events: none;
}

.lisnav {
  li {
    list-style-type: none;
  }
}

.custom-control-label-lg {
  &:after {
    width: 1.5rem;
    height: 1.5rem;
  }
  &:before {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.btn .badge.badge-favorite {
  top: 0.2em;
  right: 0.2em;
}

.form-control.datepicker {
  border-radius: 0;
}

.gj-datepicker {
  position: relative;
  .gj-icon {
    top: 50%;
    transform: translateY(-50%);
  }
}

.flash-message {
  .alert {
    padding: .75rem 0.5rem;
    @media (min-width: 768px){
      padding: .75rem 1.25rem;
    }
  }
}

.btn-pgrid-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.alert {
  margin-top: 1rem;
}

.card-icons {

}

.table-responsive-top-scrollbar {
  margin: 10px 0 0;
  width: 100%;
  overflow-x: scroll;
  .table {
    height: 8px;
  }
}

.filter-option-inner-inner {
  text-transform: initial;
}

.dropdown-item {
  position: relative;
  &.loading {
    .dropdown-item-loader {
      opacity: 1;
      visibility: visible;
    }
  }
}

.dropdown-item-loader {
  position: absolute;
  transition: opacity .3s linear;
  left: 6px;
  top: 8px;
  opacity: 0;
  visibility: hidden;
}

.bootstrap-select .dropdown-item {
  white-space: normal;
}

.td-link {
  display: block;
  max-width: 200px;
  word-break: break-all;
}