.editable-container-prices {
  position: absolute;
  padding: 8px 4px 8px 8px;
  background-color: #fff;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 8px 0 rgba(0,0,0,.12);
  top: 1px;
  left: 1px;
  z-index: 1;
  .editableform {
    .form-group {
      margin-bottom: 0;
      max-width: 100%;
    }
    .form-group > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-content: center;
    }
    .control-group {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .editable-cancel {
    display: none;
  }
  .editable-input {
    display: flex;
    .editable-custom+.editable-custom{
      margin-left: 6px;
    }
    .editable-custom {
      input {
        width: 3.2rem;
      }
      .form-control {
        padding: .35rem;
      }
    }
    @media (min-width: 768px){
      .editable-custom {
        input {
          width: 4rem;
        }
      }
    }
  }
  .editable-buttons {
    margin: 0;
  }
  .editable-error-block {
    color: #ff3547!important;
    margin-top: 8px;
  }
  .editable-submit {
    padding: .5rem 1rem;
  }
}

.editable-price-25 {
  .editable-container-prices {
    left: -57px;
    @media (min-width: 768px){
      left: 1px;
    }
  }
}

.editableform-loading {
  height: 2rem !important;
  &::before {
    content: "";
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spinner-border .75s linear infinite;
    color: #2bbbad;
    vertical-align: middle;
  }
}

.editableform--custom {
  max-width: 144px;
  .form-group {
    margin-bottom: 0;
    flex-direction: column;
    flex: 1 1 auto;
  }
  .editable-input {
    width: 132px;
    margin: 0 6px;
  }
  .form-control {
    width: 100%;
  }
  .btn {
    margin-bottom: 0;
  }
}

.editable-error-block {
  display: block;
  color: #ff3547
}

.editable-container.popover {
  z-index: 998;
}